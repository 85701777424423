import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/common/layout"
import SEO from "../../components/common/seo"

// just the submit page but with a modal over it
const SubmitAddPage = ({path}) => {

  return (
    <Layout pageTitle="Community Added" enableShare={false} >
      <SEO title="Community Added" description="Thanks for adding your community to The Hive Index."
        path={path} generateImage={false}
      />
      <h2 className="mb-8 text-xl font-bold">Thank you for adding your communtity!</h2>

      <p>If your community meets the criteria for submission, it will be live within the next week.</p>
      <p>You will receive an email when your listing is live!</p>

      <p className="mt-8">
        If you would like to promote your community right from the launch, please fill out the payment form on the <Link to="/partners/community/" className="text-rose-500 underline">community sponsorship</Link> page.
      </p>
    </Layout>
  )
}


export default SubmitAddPage


